import FormDropdown from "./formDropdown";
import ReactDOM from "react-dom";
import _ from "lodash";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import useSWR from "swr";
import { useState } from "react";
import ReactGA from "react-ga4";

interface GetYMMEPayload {
  type: string;
  vehicleTypeGroup?: string | undefined | null;
  vehicleTypes?: string[] | undefined | null;
  year?: string | undefined | null;
  make?: string | undefined | null;
  model?: string | undefined | null;
}

interface YMMEFormSelections {
  type?: string | undefined | null;
  vehicleType?: string | undefined | null;
  year?: string | undefined | null;
  make?: string | undefined | null;
  model?: string | undefined | null;
  engine?: string | undefined | null;
}

const ymmeApiEndpoint = () => `/aeapi/ymme`;

const fetcher = (payload: GetYMMEPayload | undefined) => {
  if (!payload) {
    throw new Error("Please check your ymme parameters and try again");
  }
  return fetch(ymmeApiEndpoint(), {
    method: "post",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload),
  }).then((res) => res.json());
};

export default function FindYourBattery() {
  const container = document.getElementById("findYourBattery");
  const [activeDropdown, setActiveDropdown] = useState<string>("");
  // values are stored in order to auto populate the fields
  const savedValues = sessionStorage.getItem("cusYYmmInfo");
  const parsedValues = savedValues ? JSON.parse(savedValues) : null;
  const [ymmeSelections, setYMMESelections] = useState<
    YMMEFormSelections | undefined
  >({
    type: parsedValues?.vehicleTypeGroup || "Car/Truck",
    year: parsedValues?.year,
    make: parsedValues?.make,
    model: parsedValues?.model,
    engine: parsedValues?.engine,
  });

  // Close any dropdowns when clicking outside of the component
  const ref = useOutsideClick(() => {
    setActiveDropdown("");
  });

  const handleDropdownChange = (
    dropdownStatus: boolean,
    dropdownTitle: string
  ) => {
    if (dropdownStatus) {
      setActiveDropdown(dropdownTitle);
    } else {
      setActiveDropdown("");
    }
  };

  const prepPayload = (name: string) => {
    let payload = null;
    if (!!ymmeSelections) {
      if (name === "getVehicleGroups") {
        payload = [name, { type: "VEHICLE_TYPE_GROUP" } as GetYMMEPayload];
      } else if (name === "getVehicleTypes") {
        payload = [
          name,
          ymmeSelections.type === "Powersports"
            ? ({
              type: "VEHICLE_TYPE",
              vehicleTypeGroup: ymmeSelections.type,
            } as GetYMMEPayload)
            : null,
        ];
      } else if (name === "getYears") {
        payload = [
          name,
          !!ymmeSelections.type &&
            !(
              ymmeSelections.type === "Powersports" && !ymmeSelections.vehicleType
            )
            ? ymmeSelections.type !== "Powersports"
              ? ({
                type: "YEAR",
                vehicleTypeGroup: ymmeSelections.type,
              } as GetYMMEPayload)
              : ({
                type: "YEAR",
                vehicleTypeGroup: ymmeSelections.type,
                vehicleTypes: [ymmeSelections.vehicleType],
              } as GetYMMEPayload)
            : null,
        ];
      } else if (name === "getMakes") {
        payload = [
          name,
          !!ymmeSelections.type &&
            !!ymmeSelections.year &&
            !(
              ymmeSelections.type === "Powersports" && !ymmeSelections.vehicleType
            )
            ? ymmeSelections.type !== "Powersports"
              ? ({
                type: "MAKE",
                vehicleTypeGroup: ymmeSelections.type,
                year: ymmeSelections.year,
              } as GetYMMEPayload)
              : ({
                type: "MAKE",
                vehicleTypeGroup: ymmeSelections.type,
                vehicleTypes: [ymmeSelections.vehicleType],
                year: ymmeSelections.year,
              } as GetYMMEPayload)
            : null,
        ];
      } else if (name === "getModels") {
        payload = [
          name,
          !!ymmeSelections.type &&
            !!ymmeSelections.year &&
            !!ymmeSelections.make &&
            !(
              ymmeSelections.type === "Powersports" && !ymmeSelections.vehicleType
            )
            ? ymmeSelections.type !== "Powersports"
              ? ({
                type: "MODEL",
                vehicleTypeGroup: ymmeSelections.type,
                year: ymmeSelections.year,
                make: ymmeSelections.make,
              } as GetYMMEPayload)
              : ({
                type: "MODEL",
                vehicleTypeGroup: ymmeSelections.type,
                vehicleTypes: [ymmeSelections.vehicleType],
                year: ymmeSelections.year,
                make: ymmeSelections.make,
              } as GetYMMEPayload)
            : null,
        ];
      } else if (name === "getEngines") {
        payload = [
          name,
          !!ymmeSelections.type &&
            !!ymmeSelections.year &&
            !!ymmeSelections.make &&
            !!ymmeSelections.model &&
            ymmeSelections.type !== "Powersports"
            ? ({
              type: "ENGINE",
              vehicleTypeGroup: ymmeSelections.type,
              year: ymmeSelections.year,
              make: ymmeSelections.make,
              model: ymmeSelections.model,
            } as GetYMMEPayload)
            : null,
        ];
      }
    } else {
      payload = [name, null];
    }

    if (payload?.[1] === null) {
      return null;
    } else {
      return payload;
    }
  };

  const {
    data: types,
    error: typesError,
    isLoading: typesIsLoading,
    isValidating: typesIsValidating,
  } = useSWR(
    prepPayload("getVehicleGroups"),
    (vars) =>
      fetcher({ ...(vars[1] as GetYMMEPayload) }).then((y) => {
        return (y.vehicleTypeGroups as any[])?.sort((a, b) => {
          return +b - +a;
        });
      }),
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );

  const {
    data: vehicleTypes,
    error: vehicleTypesError,
    isLoading: vehicleTypesIsLoading,
    isValidating: vehicleTypesIsValidating,
  } = useSWR(
    prepPayload("getVehicleTypes"),
    (vars) =>
      fetcher({ ...(vars[1] as GetYMMEPayload) }).then(
        (m) => m.vehicleTypes as any[]
      ),
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );

  const {
    data: years,
    error: yearsError,
    isLoading: yearsIsLoading,
    isValidating: yearsIsValidating,
  } = useSWR(
    prepPayload("getYears"),
    (vars) =>
      fetcher({ ...(vars[1] as GetYMMEPayload) }).then((m) =>
        (m.years as any[]).sort().reverse()
      ),
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );

  const {
    data: makes,
    error: makesError,
    isLoading: makesIsLoading,
    isValidating: makesIsValidating,
  } = useSWR(
    prepPayload("getMakes"),
    (vars) =>
      fetcher({ ...(vars[1] as GetYMMEPayload) }).then((m) => m.makes as any[]),
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );

  const {
    data: models,
    error: modelsError,
    isLoading: modelsIsLoading,
    isValidating: modelsIsValidating,
  } = useSWR(
    prepPayload("getModels"),
    (vars) =>
      fetcher({ ...(vars[1] as GetYMMEPayload) }).then(
        (m) => m.models as any[]
      ),
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );

  const {
    data: engines,
    error: enginesError,
    isLoading: enginesIsLoading,
    isValidating: enginesIsValidating,
  } = useSWR(
    prepPayload("getEngines"),
    (vars) =>
      fetcher({ ...(vars[1] as GetYMMEPayload) }).then(
        (m) => m.engines as any[]
      ),
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );

  const typeChangeHandler = (type: string) => {
    if (type === "Powersports") {
      setYMMESelections({ type: type, vehicleType: "ATV/UTV" });
    } else {
      setYMMESelections({ type: type });
    }
  };

  const vehicleTypeChangeHandler = (vehicleType: string) => {
    setYMMESelections({ type: ymmeSelections?.type, vehicleType: vehicleType });
  };

  const yearChangeHandler = (year: string) => {
    setYMMESelections({
      type: ymmeSelections?.type,
      year: year,
      vehicleType: ymmeSelections?.vehicleType,
    });
  };

  const makeChangeHandler = (make: string) => {
    setYMMESelections({
      type: ymmeSelections?.type,
      year: ymmeSelections?.year,
      make: make,
      vehicleType: ymmeSelections?.vehicleType,
    });
  };

  const modelChangeHandler = (model: string) => {
    setYMMESelections({
      type: ymmeSelections?.type,
      year: ymmeSelections?.year,
      make: ymmeSelections?.make,
      vehicleType: ymmeSelections?.vehicleType,
      model: model,
    });
  };

  const engineChangeHandler = (engine: string) => {
    setYMMESelections({
      type: ymmeSelections?.type,
      year: ymmeSelections?.year,
      make: ymmeSelections?.make,
      model: ymmeSelections?.model,
      vehicleType: ymmeSelections?.vehicleType,
      engine: engine,
    });
  };

  const goToResults = () => {
    if (ymmeSelections) {
      const parsedValues = JSON.stringify(ymmeSelections);
      sessionStorage.setItem("cusYYmmInfo", parsedValues);
    }
    //search?cat1=PZ_ALL&cat2=Batteries&cat3=Batteries&from=0&y=2023&mk=Chevrolet&md=Express%204500&sort=1&eng=V8%206.6L%20GAS&ymmType=AUTO&sortType=DESC
    ///search?cat1=PZ_ALL&cat2=Batteries&cat3=Batteries&from=0&y=2020&mk=Kawasaki&md=JT1500%20STX160&sort=1&ymmType=AUTO&sortType=DESC
    ///search?cat1=PZ_ALL&cat2=Batteries&cat3=Batteries&from=0&y=2023&mk=Alfa%20Romeo&md=Giulia&sort=1&eng=L4%202.0L%20GAS&ymmType=AUTO&sortType=DESC
    let redirect = "";
    if (ymmeSelections?.type === "Powersports") {
      redirect = `/search?y=${ymmeSelections?.year}&mk=${ymmeSelections?.make}&md=${ymmeSelections?.model}&eqtype=${ymmeSelections?.vehicleType}&type=powersports`;
    } else if (ymmeSelections?.type === "Medium/Heavy Truck") {
      redirect = `/search?y=${ymmeSelections?.year}&mk=${ymmeSelections?.make}&md=${ymmeSelections?.model}&eng=${ymmeSelections?.engine}&type=mediumTruck`;
    } else if (ymmeSelections?.type === "Car/Truck") {
      redirect = `/search?y=${ymmeSelections?.year}&mk=${ymmeSelections?.make}&md=${ymmeSelections?.model}&eng=${ymmeSelections?.engine}&type=auto`;
    }

    trackGtag();

    window.location.href = redirect
      .replace("&eng=&", "&")
      .replace("&eng=undefined", "");
  };

  const trackGtag = () => {
    let formParams = {
      event: 'find_a_battery_search',
      target: "Search",
      action: "Application Search",
      'target-properties': "CXP",
      value: 1,
      'interaction-type': false,
      find_a_battery_year: ymmeSelections?.year || "",
      find_a_battery_make: ymmeSelections?.make || "",
      find_a_battery_model: ymmeSelections?.model || "",
      find_a_battery_engine: ymmeSelections?.engine || "",
      find_a_battery_equipment_type: ymmeSelections?.type === "Powersports" ? ymmeSelections?.vehicleType : "",
      find_a_battery_type: ymmeSelections?.type || "",
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(formParams);
    // window.gtag('event', 'find_a_battery_search', formParams);
  }
  return (
    container &&
    ReactDOM.createPortal(
      <div ref={ref}>
        <FormDropdown
          activeDropdown={activeDropdown}
          options={types}
          disabled={false}
          onDropdownChange={handleDropdownChange}
          onChangeHandler={typeChangeHandler}
          title={ymmeSelections?.type ?? "Vehicle Group Type"}
          displayType="secondary"
        ></FormDropdown>

        {ymmeSelections?.type === "Marine/RV" ? (
          <>
            <p className="find-your-battery__summary">
              Ready to replace your Marine or RV battery? Go further, faster and for longer with batteries for marine and RV applications. Our OPTIMA&reg; BLUETOP&reg; features SPIRALCELL TECHNOLOGY&reg; with 99.99% pure lead for extended power and performance. The all-new lithium powered ORANGETOP&trade; is a high-capacity, long-lasting battery, featuring Bluetooth and NMEA 2000 compatibility.
            </p>
            <a
              href="/products/marine-rv"
              className="button find-your-battery__button"
              onClick={() => trackGtag()}
            >
              Shop Marine / RV Batteries
            </a>
          </>
        ) : (
          <>
            {ymmeSelections?.type === "Powersports" && (
              <FormDropdown
                activeDropdown={activeDropdown}
                options={vehicleTypes}
                disabled={!ymmeSelections?.type}
                onDropdownChange={handleDropdownChange}
                onChangeHandler={vehicleTypeChangeHandler}
                title={ymmeSelections?.vehicleType ?? "Search Equipment Type"}
                showInput={true}
                searchPlaceholder="Search Equipment Type"
              ></FormDropdown>
            )}

            <FormDropdown
              activeDropdown={activeDropdown}
              options={years}
              disabled={
                !ymmeSelections?.type ||
                (ymmeSelections?.type === "Powersports" &&
                  !ymmeSelections?.vehicleType)
              }
              onDropdownChange={handleDropdownChange}
              onChangeHandler={yearChangeHandler}
              title={ymmeSelections?.year ?? "Select Year"}
              showInput={true}
              searchPlaceholder="Search Year"
            ></FormDropdown>

            <FormDropdown
              activeDropdown={activeDropdown}
              options={makes}
              disabled={!ymmeSelections?.year}
              onDropdownChange={handleDropdownChange}
              onChangeHandler={makeChangeHandler}
              title={ymmeSelections?.make ?? "Select Make"}
              showInput={true}
              searchPlaceholder="Search Make"
            ></FormDropdown>

            <FormDropdown
              activeDropdown={activeDropdown}
              options={models}
              disabled={!ymmeSelections?.make}
              onDropdownChange={handleDropdownChange}
              onChangeHandler={modelChangeHandler}
              title={ymmeSelections?.model ?? "Select Model"}
              showInput={true}
              searchPlaceholder="Search Model"
            ></FormDropdown>

            {ymmeSelections?.type !== "Powersports" && (
              <FormDropdown
                activeDropdown={activeDropdown}
                options={engines}
                disabled={!ymmeSelections?.model}
                onDropdownChange={handleDropdownChange}
                onChangeHandler={engineChangeHandler}
                title={ymmeSelections?.engine ?? "Select Engine"}
                showInput={true}
                searchPlaceholder="Search Engine"
              ></FormDropdown>
            )}

            <button
              type="button"
              className="button find-your-battery__button"
              onClick={goToResults}
              disabled={
                !ymmeSelections ||
                !ymmeSelections.type ||
                (ymmeSelections.type === "Powersports" &&
                  !ymmeSelections.vehicleType) ||
                !ymmeSelections.year ||
                !ymmeSelections.make ||
                !ymmeSelections.model
              }
            >
              Search
            </button>
          </>
        )}
      </div>,
      container
    )
  );
}
